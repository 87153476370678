<template>
  <div class="pending-approval">
    <view-switcher
      :layout="layout"
      :loading="loading"
      :length="profiles.length"
      class="pending-approval__view-switcher"
      @toggleView="toggleView"
    />
    <TabView>
      <TabPanel>
        <template #header>
          Profile <span class="desktop-only">&nbsp;approval</span>
          <Badge
            v-if="profilesForApproval.length"
            :value="profilesForApproval.length"
            class="p-ml-2"
          ></Badge>
        </template>
        <div class="p-grid">
          <div class="p-col-12 p-p-0">
            <list-view
              v-if="layout === 'list'"
              :profiles="profilesForApproval"
              :show-pending-approval="true"
              :loading="loading"
              :load-profiles="loadProfiles"
              :profile-number="profileNumber"
            />
            <grid-view
              v-else
              :profiles="profilesForApproval"
              :show-pending-approval="true"
              :loading="loading"
              :load-profiles="loadProfiles"
              :profile-number="profileNumber"
            />
            <div v-if="profilesForApproval.length === 0">
              <empty-mails
                :empty-text="'No profiles pending for approval'"
                :img="'/pending_profile.png'"
              />
            </div>
          </div></div
      ></TabPanel>
      <TabPanel>
        <template #header>
          Photo <span class="desktop-only">&nbsp;approval</span>
          <Badge
            v-if="photosForApproval.length"
            :value="photosForApproval.length"
            class="p-ml-2"
          ></Badge>
        </template>
        <div class="p-grid">
          <div class="p-col-12 p-p-0">
            <list-view
              v-if="layout === 'list'"
              :profiles="photosForApproval"
              :show-pending-approval="true"
              :loading="loading"
              :load-profiles="loadProfiles"
              :profile-number="profileNumber"
            />
            <grid-view
              v-else
              :profiles="photosForApproval"
              :show-pending-approval="true"
              :loading="loading"
              :load-profiles="loadProfiles"
              :profile-number="profileNumber"
            />
            <div v-if="photosForApproval.length === 0">
              <empty-mails
                :empty-text="'No photos pending for approval'"
                :img="'/pending_photo.png'"
              />
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <template #header>
          Rejected
          <Badge
            v-if="rejectedProfiles.length"
            :value="rejectedProfiles.length"
            class="p-ml-2"
          ></Badge
        ></template>
        <div class="p-grid">
          <div class="p-col-12 p-p-0">
            <list-view
              v-if="layout === 'list'"
              :profiles="rejectedProfiles"
              :show-pending-approval="true"
              :loading="loading"
              :load-profiles="loadProfiles"
              :profile-number="profileNumber"
            />
            <grid-view
              v-else
              :profiles="rejectedProfiles"
              :show-pending-approval="true"
              :loading="loading"
              :load-profiles="loadProfiles"
              :profile-number="profileNumber"
            />
            <div v-if="rejectedProfiles.length === 0">
              <empty-mails
                :empty-text="'No rejected profiles found'"
                :img="'/reject.png'"
              />
            </div>
          </div>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import GridView from '@/components/views/GridView.vue';
import ListView from '@/components/views/ListView.vue';
import { mapGetters } from 'vuex';
import EmptyMails from '@/components/EmptyMails.vue';
import { mailboxMixin } from '@/mixins/mailboxMixin';
import ViewSwitcher from '@/utilities/ViewSwitcher.vue';
import { profileApprovalMixin } from '@/mixins/profileApprovalMixin.js';

export default {
  components: {
    GridView,
    ListView,
    EmptyMails,
    ViewSwitcher,
  },
  mixins: [mailboxMixin, profileApprovalMixin],
  data() {
    return {
      reqUrl: 'users/pending_approval',
    };
  },
  computed: {
    ...mapGetters(['authUser']),
    profilesForApproval() {
      return this.profiles.filter(
        (data) =>
          !data.attributes.is_approved && data.attributes.comment === null
      );
    },
    photosForApproval() {
      return this.profiles.filter(
        (data) =>
          data.attributes.is_approved &&
          data.attributes['has_unapporved_photos?']
      );
    },
    rejectedProfiles() {
      return this.profiles.filter((data) => data.attributes.comment != null);
    },
  },
};
</script>
<style lang="scss">
.pending-approval {
  position: relative;
  &__view-switcher {
    position: absolute;
    right: 0;
    top: 5px;
  }
  .p-tabview-nav {
    border: none !important;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .mobv {
    margin-right: 5px;
  }
  .p-tabview-nav-link {
    line-height: 22px;
  }
  @media only screen and (max-width: $mobileView) {
    .p-tabview-nav-link .desktop-only {
      display: none;
    }
  }
}
</style>
